import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../style/home.css';
import axios from "axios";
import Swal from "sweetalert2";


class DepositMoney extends React.Component {

    constructor() {
        super();
        this.state = {
            amount: "",
            displayPopup:"none",
            upi:"",
            mcc:"",
            min_amount:0,
            history:[]
        }
    }

    componentDidMount() {

        var form = new FormData();
        form.append("session", localStorage.getItem("token"));
        form.append("mobile", localStorage.getItem("mobile"));

        axios
            .post(process.env.REACT_APP_URL+"/api2/get_upi.php", form,
            )
            .then((response) => {
                if(response.status === 200){
                    if (response.data.nosession){
                        Swal.fire({
                            toast: true,
                            title: 'Oops...',
                            text: "Session Expired"
                        }).then((e)=>{
                            localStorage.clear()
                            window.location.href = "/login";
                        })
                    }
                    localStorage.setItem("wallet", response.data.wallet)
                    this.setState({
                        upi:response.data.upi,
                        mcc:response.data.merchant,
                        min_amount:response.data.min_deposit,
                        history:response.data.data
                    })
                } else if(response.status === 401){
                    //  navigate("/login")
                }
            })
    }


    render() {


        let apiCall = () =>{
            var form = new FormData();
            form.append("session", localStorage.getItem("token"));
            form.append("mobile", localStorage.getItem("mobile"));
            form.append("amount", this.state.amount);

            const baseurl = process.env.REACT_APP_URL;
            axios
                .post(`${baseurl}/api2/get_payment_web.php`,form)
                .then((response) => {
                    if(response.data.success === "1"){
                        Swal.fire({
                            toast: true,
                            title: 'Deposit Request',
                            text: "Your deposit request received by our team, our team will review your request in sometime"
                        }).then((result)=>{
                            window.location.href = "/home"
                        })

                    } else {
                        Swal.fire({
                            toast: true,
                            title: 'Oops...',
                            text: response.data.msg
                        }).then((result)=>{
                            window.location.href = "/home"
                        })
                    }
                })
        }

        let depositKnow = ()=>{
            this.setState({
                displayPopup:"none"
            })
        }

        let tez = ()=>{
            if (this.state.amount === "" || parseInt(this.state.amount) < this.state.min_amount){
                Swal.fire({
                    toast: true,
                    title: 'Oops...',
                    text: "Minimum deposit amount should be "+this.state.min_amount
                })
            } else {
                let tid = Date.now()
                this.setState({
                    displayPopup:""
                })
                window.open("tez://upi/pay?pa="+this.state.upi+"&pn="+process.env.REACT_APP_NAME+"&tn="+process.env.REACT_APP_NAME+"&am="+this.state.amount+"&cu=INR&mc="+this.state.mcc+"&tr="+tid, '_blank')
            }
        }

        let phonepe = ()=>{
            if (this.state.amount === "" || parseInt(this.state.amount) < this.state.min_amount){
                Swal.fire({
                    toast: true,
                    title: 'Oops...',
                    text: "Minimum deposit amount should be "+this.state.min_amount
                })
            } else {
                let tid = Date.now()
                this.setState({
                    displayPopup:""
                })
                window.open("upi://pay?pa="+this.state.upi+"&pn="+process.env.REACT_APP_NAME+"&tn="+process.env.REACT_APP_NAME+"&am="+this.state.amount+"&cu=INR&mc="+this.state.mcc+"&tr="+tid, '_blank')
            }
        }

        let paytm = ()=>{
            if (this.state.amount === "" || parseInt(this.state.amount) < this.state.min_amount){
                Swal.fire({
                    toast: true,
                    title: 'Oops...',
                    text: "Minimum deposit amount should be "+this.state.min_amount
                })
            } else {
                let tid = Date.now()
                this.setState({
                    displayPopup:""
                })
                window.open("upi://pay?pa="+this.state.upi+"&pn="+process.env.REACT_APP_NAME+"&tn="+process.env.REACT_APP_NAME+"&am="+this.state.amount+"&cu=INR&mc="+this.state.mcc+"&tr="+tid, '_blank')
            }
        }

        return (<>
                <div className="RootView">
                    <div className="RootWidth">

                        <div className="loginDiv">


                            <div className="toolbar">
                                <div>
                                    <img src="../images/back.png"  onClick={()=>{window.history.go(-1)}} style={{padding: "5px"}}/>
                                    <span>Deposit Money</span>
                                </div>


                            </div>

                            <div style={{padding:"15px"}}>

                                <input
                                    style={{width:"100%"}}
                                    className="edittext think-border2"
                                    name="amount"
                                    type="number"
                                    value={this.state.amount}
                                    onChange={(e)=>{this.setState({amount:e.target.value})}}
                                    placeholder="Enter Coins"
                                />

                                <div className="upi_apps">
                                    <div onClick={()=>{tez()}}>
                                        <img src="../images/googlepay.png" />
                                        <span className="app-name">Google Pay</span>
                                    </div>
                                    <div onClick={()=>{phonepe()}}>
                                        <img src="../images/phone_pe.png" />
                                        <span className="app-name">PhonePe</span>
                                    </div>
                                    <div onClick={()=>{paytm()}}>
                                        <img src="../images/paytm.png" />
                                        <span className="app-name">Paytm</span>
                                    </div>
                                    <div onClick={()=>{phonepe()}}>
                                        <img src="../images/other.png" />
                                        <span className="app-name">Other Apps</span>
                                    </div>
                                </div>


                                <div style={{padding:"15px"}}>

                                    { this.state.history && this.state.history.map((result) => {
                                        return (
                                            <div className="transaction_block" key={result.sn}>
                                                <div>
                                                    <span className="">{result.remark}</span>
                                                    <span>{result.date}</span>
                                                </div>
                                                <span>{result.amount}</span>
                                            </div>
                                        )})}

                                </div>

                                <div className="confirm-box" style={{display:this.state.displayPopup}}>
                                    <span className="title">Have you completed the payment ?</span>
                                    <div>
                                        <button className="button bg-success" onClick={apiCall}>
                                            <b>Yes</b>
                                        </button>
                                        <button className="button bg-danger" onClick={depositKnow}>
                                            <b>No</b>
                                        </button>
                                    </div>
                                </div>

                            </div>

                        </div>


                    </div>
                </div>


            </>
        );
    }

}

export default DepositMoney;

