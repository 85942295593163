import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../style/home.css';
import axios from "axios";


class Wallet extends React.Component {

    constructor() {
        super();
        this.state = {
            type: "",
            wallet: "",
            history:[]
        }
    }

    componentDidMount() {

        const windowUrl = window.location.search;

        const query = new URLSearchParams(windowUrl);
        this.setState({
            type:query.get('type')
        })

        console.log(this.state.type)

        var form = new FormData();
        form.append("session", localStorage.getItem("token"));
        form.append("mobile", localStorage.getItem("mobile"));
        form.append("type", query.get('type'));


        axios
            .post(process.env.REACT_APP_URL+"/api2/games.php", form,
            )
            .then((response) => {
                console.log(response.data)
                if(response.status === 200){
                    localStorage.setItem("wallet", response.data.wallet)
                    this.setState({
                        wallet:response.data.wallet,
                        history:response.data.data
                    })
                } else if(response.status === 401){
                    //  navigate("/login")
                }
            })
    }


    render() {


        return (<>
                <div className="RootView">
                    <div className="RootWidth">

                        <div className="loginDiv">


                            <div className="toolbar">
                                <div>
                                    <img onClick={()=>{window.history.go(-1)}}  src="../images/back.png" style={{padding: "5px"}}/>
                                    <span>Game History</span>
                                </div>


                            </div>

                            <div style={{padding:"15px"}}>

                                { this.state.history && this.state.history.map((result) => {
                                    return (
                                        <div className="transaction_block borderdiv" style={{marginBottom:"10px"}} key={result.sn}>
                                            <div style={{width:"50%"}}>
                                                <span style={{fontSize:"13px"}}>{result.date}</span>
                                                <span>{result.bazar.replace("_"," ")}</span>
                                            </div>
                                            <div style={{width:"20%"}}>
                                                <span className="">Bet</span>
                                                <span>{result.number}</span>
                                            </div>
                                            <div style={{width:"20%"}}>
                                                <span className="">Coin</span>
                                                <span>{result.amount}</span>
                                            </div>
                                        </div>
                                    )})}

                            </div>

                        </div>


                    </div>
                </div>


            </>
        );
    }

}

export default Wallet;
