import { useCallback , useState } from "react";
import {Link, Navigate, useNavigate} from "react-router-dom";
import styles from "../style/Login.module.css"
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import Swal from "sweetalert2";
import {bindActionCreators} from "redux";
import {actions} from "../state/index"

const Login = () => {
    const navigate = useNavigate();

    const state = useSelector(state => state.session)

    const dispatch = useDispatch()
    const {setSession, getSession} = bindActionCreators(actions, dispatch)

    const [mobileNumber, setMobileNumber] = useState("");
    const [pass, setPassword] = useState("");
    const [name, setName] = useState("");

    let loginSubmit = (event) =>{
        event.preventDefault();


        var form = new FormData();
        form.append("mobile", mobileNumber);
        form.append("pass", pass);
        form.append("name", name);


        axios
            .post(process.env.REACT_APP_URL+"/api2/register.php", form)
            .then((response) => {
                if(response.data.success === "0"){
                    Swal.fire({
                        toast: true,
                        title: 'Oops...',
                        text: response.data.msg,
                        footer: '<link to="forgot">Forgot Password ?</a>'
                    })

                } else {
                    setSession(response.data.token, mobileNumber)
                    navigate("/home")
                }
            })

        if (state){
            return <Navigate to={"/home"} />
        }
    }

    const onForgotClick = useCallback(() => {
        navigate("/forgot");
    }, [navigate]);

    return (
        <div className="RootView">
            <div className="RootWidth authBack">
                <div className={styles.loginDiv}>
                    <img className="logoIcon" alt="" src="../images/logo.png" />

                    <form
                        className={styles.frameForm}
                        method="post"
                        onSubmit={loginSubmit}
                    >
                        <div className="pageHeadings">Login your account</div>
                        <input
                            className="edittext"
                            name="name"
                            type="text"
                            value={name}
                            onChange={(e)=>{setName(e.target.value)}}
                            placeholder="Enter Name"
                        />

                        <input
                            className="edittext"
                            name="mobile"
                            type="number"
                            value={mobileNumber}
                            onChange={(e)=>{setMobileNumber(e.target.value)}}
                            placeholder="Enter mobile number"
                        />

                        <input className="edittext" type="password"
                               name="passwword"
                               value={pass}
                               placeholder="Enter password"
                               onChange={(e)=>{setPassword(e.target.value)}}
                        />

                        <div className={styles.frameDiv}>
                            <div className={styles.forgotDiv} onClick={onForgotClick}>Forgot Password ?</div>
                        </div>

                        <button className="button">
                            <b>REGISTER</b>
                        </button>

                        <button className="button">
                            <Link to="/login">
                                <b>ALREADY HAVE ACCOUNT</b>
                            </Link>
                        </button>

                    </form>

                </div>
            </div>
        </div>
    );
};

export default Login;
