import {useCallback, useEffect, useState} from "react";
import {Link, Navigate, useLocation, useNavigate} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../style/home.css';

const Home = (props) => {

    const [amount, setamount] = useState("");
    let data = useLocation();

    if (data.state == null){
        window.location.href = "/";
    }
    let marketName = data.state[0].market;
    let is_open_status = data.state[0].is_open;
    // function back(){
    //     let navigate = useNavigate();
    //     navigate(-1)
    // }
    useEffect(() => {
        window.scrollTo({ top:0, left:0, behavior: "instant"})
    }, [])

    return (<>
            <div className="RootView">
                <div className="RootWidth">

                    <div className="loginDiv">


                        <div className="toolbar">
                            <div>
                                <img onClick={()=>{window.history.go(-1)}}  src="../images/back.png" style={{padding: "5px"}}/>
                                <span>Select Game</span>
                            </div>


                        </div>

                        <div style={{ paddingBottom:"30px"}}>

                            <div className="games">
                                <Link to={"/order"} state={[{market: marketName, is_open: is_open_status, game: 'single'}]}>
                                    <div  className="gamediv">
                                        <div >
                                           <img src="../images/singlee.png" />
                                        </div>
                                        <span>Single Digits</span>
                                    </div>
                                </Link>

                                <Link to={"/oddeven"} state={[{market: marketName, is_open: is_open_status}]}>
                                    <div  className="gamediv">
                                        <div >
                                           <img src="../images/singlee.png" />
                                        </div>
                                        <span>Odd Even</span>
                                    </div>
                                </Link>

                              
                            </div>

                            { is_open_status === "1" ?
                                <div className="games">

                                    <Link to={"/order"} state={[{market: marketName, is_open: is_open_status, game: 'jodi'}]}>
                                        <div  className="gamediv">
                                            <div >
                                            <img src="../images/ic_jodi_digit (1).png" />
                                            </div>
                                            <span>Jodi Digits</span>
                                        </div>
                                    </Link>
                                    

                                    <Link to={"/redbracket"} state={[{market: marketName, is_open: is_open_status, game: 'jodi'}]}>
                                        <div  className="gamediv">
                                            <div >
                                            <img src="../images/ic_jodi_digit (1).png" />
                                            </div>
                                            <span>Red Bracket</span>
                                        </div>
                                    </Link>

                               
                                </div>
                                : "" }

                            <div className="games">

                                <Link to={"/order"} state={[{market: marketName, is_open: is_open_status, game: 'singlepanna'}]}>
                                    <div  className="gamediv">
                                        <div >
                                        <img src="../images/ic_singkle_pana (1).png" />
                                        </div>
                                        <span>Single Pana</span>
                                    </div>
                                </Link>

                                <Link to={"/order"} state={[{market: marketName, is_open: is_open_status, game: 'doublepanna'}]}>
                                    <div  className="gamediv">
                                        <div >
                                        <img src="../images/ic_double_pana (1).png" />
                                        </div>
                                        <span>Double Pana</span>
                                    </div>
                                </Link>
                            
                            </div>


                            
                            <div className="games">
                             
                             
                            <Link to={"/order"} state={[{market: marketName, is_open: is_open_status, game: 'triplepanna'}]}>
                                    <div  className="gamediv">
                                        <div >
                                        <img src="../images/ic_triple_pana (1).png" />
                                        </div>
                                        <span>Triple Pana</span>
                                    </div>
                                </Link>
                            
                            
                                <Link to={"/spdptp"} state={[{market: marketName, is_open: is_open_status, game: 'triplepanna'}]}>
                                    <div  className="gamediv">
                                        <div >
                                        <img src="../images/spdptp (1).png" />
                                        </div>
                                        <span>SP DP TP</span>
                                    </div>
                                </Link>
                            
                            </div>

                            <div className="games">
                                <Link to={"/motor"} state={[{market: marketName, is_open: is_open_status, game: 'singlepanna'}]}>
                                    <div  className="gamediv">
                                        <div >
                                        <img src="../images/spmotor (1).png" />
                                        </div>
                                        <span>SP Motor</span>
                                    </div>
                                </Link>
                            
                                <Link to={"/motor"} state={[{market: marketName, is_open: is_open_status, game: 'doublepanna'}]}>
                                    <div  className="gamediv">
                                        <div >
                                        <img src="../images/dpmotor (1).png" />
                                        </div>
                                        <span>DP Motor</span>
                                    </div>
                                </Link>
                            </div>

                            { is_open_status === "1" ?
                                <div className="games">


                                <Link to={"/halfsangam"} state={[{market: marketName, is_open: is_open_status, game: 'halfsangam'}]}>
                                    <div  className="gamediv">
                                        <div >
                                        <img src="../images/ic_half_sangam (1).png" />
                                        </div>
                                        <span>Half Sangam</span>
                                    </div>
                                </Link>
                            
                                <Link to={"/fullsangam"} state={[{market: marketName, is_open: is_open_status, game: 'fullsangam'}]}>
                                    <div  className="gamediv">
                                        <div >
                                        <img src="../images/ic_full_sangam (1).png" />
                                        </div>
                                        <span>Full Sangam</span>
                                    </div>
                                </Link>

                                
                                </div>
                                : ""}

                        </div>

                    </div>


                </div>
            </div>


        </>
    );
};

export default Home;
